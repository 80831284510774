.adminOrgLink.disabled {
  opacity: .3;
  cursor: not-allowed;
}

.adminOrgLinkIcon {
  padding-top: 2px;
  margin-right: 5px !important;
}

.adminOrgDivider {
  background-color: #dedede !important;
  margin-right: 0px !important;
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;