.commonSearchBar {
  margin-bottom: 40px;
}
.searchBarRow {
  background-color: white;
  padding: 15px 0 15px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  width: 70%;
  min-width: 700px !important;
}
.searchBarInput {
  .ant-input-group-addon {
    display: none;
  }
  input {
    line-height: 25px;
    font-size: 16px !important;
    border: 0 !important;
    background: none !important;
  }
 }
.searchBarIcon {
  font-size: 28px;
  margin-right: 50px;
  margin-left: 20px;
}
/* Buttons */
.searchButton {
  font-weight: 500;
}

/* Filter */
.searchFilterRow {
  margin-top: 30px;
  .searchCitiesSelect, .searchCUsSelect {
    margin-left: 10px;
    min-width: 300px;
  }
  .ant-select-selection { border-radius: 0 !important; }
  .ant-select-selection__rendered::after { width: 4px !important; }
}
 .searchTypeSelect {
   min-width: 110px;

   .ant-select-selection {
     border-radius: 0 !important;
   }

   .ant-select-selection__rendered::after {
     width: 4px !important;
   }
 }

 /* Grid */
.searchInputColumn {
  flex-grow: 1;
  .ant-form-explain {
    position: absolute;
    font-size: 12px;
    z-index: 99;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
.buttonColumn {
  margin-left: 20px;
  margin-right: 20px;
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;