body {
  padding: 0;
  min-width: 300px !important;
}

section {
  background-color: white !important;
}

/* Navigation */

.sideNavigation {
  position: fixed !important;
  width: 200px;
  height: 100vh;
  top: 0;
}

ul.sideNavigation {
  padding-top: 170px !important;
  border-right:  1.3px solid #d9d9d9 !important;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2) !important;
  li {
    .ant-menu-title-content {
      color: #444444 !important;
      a { color: #444444 !important; }
      .anticon { margin-right: 4px; }
    }
  }
}

/* Page - w/ nav and header */
.pageHeader {
  margin-top: 10px !important;
  border-bottom-width: 1px;
}
.pageContent {
  padding: 30px;
  height: 100%;
}
.pageSubContent {
  padding: 30px;
}
// Layout
section.container-layout {
  height: 100vh !important;
}
/* Alert */
.alertController {
  z-index: 9999;
}

/* Default logo */
.bwLogo {
  height: 50px;
  margin-bottom: 20px;
}
.bwLogo-small {
  height: 25px;
  margin-bottom: 10px;
}

/* User Badge */
.idmBadgeContainer {
  margin-right: 20px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  &:hover { 
    opacity: 1 !important; 
  }
  .idmBadgeUserIcon {
    font-size: 16px !important;
    color: white !important;
    box-shadow: 2px -1px 9px 3px rgba(0, 0, 0, 0.1);
  }
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;