.settingsCard {
  background-color: #fff;
  border: 2px solid #DFE6EF;
  border-radius: 6px;
  padding: 20px;
  cursor: pointer;
  position: relative;
  transition: all 0.15s ease-in;
  display: flex;
  box-shadow: 0px 2px 4px 0px #00000026;
  &:hover {
    h3, span {
      color: #fff;
    }
  }

  .settingsCardInfo {
    margin-left: 10px;
  }

  h3 {
    transition: all 0.15s ease-in;
    font-size: 20px;
    color: #000;
  }

  span {
    transition: all 0.15s ease-in;
    font-size: 14px;
    color: #666;
  }

  .settingsCardIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    
    svg {
      color: #000;
    }
  }
}

.adminNavigationView {
  padding-top: 20px;
  margin-left: 60px;
  margin-bottom: -60px;
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;