.resetContainer {
  height: 100% !important;
  min-height: calc(100vh - 80px) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.resetBoxContainer {
  border:  1px solid #dadce0;
  border-radius: 8px;
  -webkit-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  -moz-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  min-width: 500px;
  margin-bottom: 20px;
}
.resetBox {
  margin: 30px 30px 20px 30px;
}
.resetBoxTitle {
  font-weight: 600;
  font-weight: 600;
  font-size: 20px !important;
}
.resetBoxDescription {
  margin-bottom: 15px;
  font-weight: 400;
}
.resetForm {
  margin-top: 40px !important;
}
.resetButton {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 150px !important;
  height: 40px !important;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;