.uploads-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;

  .upload-item {
    border: 1px solid #eee;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    text-align: center;

    span {
      margin-top: 12px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-top: 12px;
      border-top: 1px solid #eee;
      padding-top: 16px;

      button {
        line-height: 0;
        background-color: transparent;
        border: none;
        color:#C5303C;
        cursor: pointer;

        &:disabled {
          color: #666;
          cursor: default;
          opacity: .3;
        }
      }
    }

    &.new-upload {
      background-color: #fff;
      justify-content: center;
      border: 1px dashed #C5303C;
      padding: 0;
      overflow: hidden;

      .upload-button {
        width: 300px;
        min-height: 180px;
        color: #C5303C;
        background-color: transparent;
        font-size: 16px;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }
  }
}

.bottom-actions {
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-left: -24px;
  padding: 24px;
  box-shadow: 0 -25px 15px -15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 224px;
}

.bottom-actions > Button:last-child {
  margin-left: auto;
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;