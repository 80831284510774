.uploadModal {
  .ant-modal-body {
    h1 {
      font-weight: bold;
      font-size: 24px;
    }
    .ant-upload-drag-icon {
      svg {
        path {
          stroke: #106D37;
        }
      }
    }
  }
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;