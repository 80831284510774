/* #headers stuff */
.appHeader {
  box-shadow: 2px -1px 9px 3px rgba(0,0,0,0.2);
  z-index: 999;
  height: 80px !important;
  background-repeat: repeat-x;
  background-color: #ffffff !important;
}
.headingLogo {
  // width: 140px;
  height: 32px;
  margin-top: 12px;
  margin-left: 6px;
}
.headerSubtitle {
  color: #444444 !important;
  margin-top: 24px;
  margin-right: 6px;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 31px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
}

.userAvatar {
  margin-top: 14px !important;
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;