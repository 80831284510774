.whiteBox {
  width: 100%;
  background: #fff;
  padding: 16px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  > header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;

    strong {
      font-size: 22px;
    }
  }
}
.whiteBox.secondary {
  background: rgba(0,0,0,0.04);
  padding: 16px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: unset;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;