/* #headers stuff */
.appUserHeader {
  box-shadow: 2px -1px 9px 3px rgba(0,0,0,0.1);
  z-index: 999;
  height: 70px !important;
  background-repeat: repeat-x;
}

// Common
.appUserHeaderDivider {
  background: #000 !important;
  height: 30px !important;
  margin-top: 8px !important;
  opacity: .2;
  margin-right: 22px !important;
}

// Back to tenant button
.backTenantButton {
  margin-top: 6px;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  &:focus > span, &:active > span {
    position: initial !important;
  }
  svg {
    font-size: 32px !important;
    position: absolute;
    bottom: 0px !important;
    left: -20px;
  }
}

// Welcome title
.welcomeTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-top: 10px;
}

// User menu
.userMenu {
  margin: 15px 0px 0 0 !important;
  font-size: 17px !important;
  font-weight: 500;
  border-radius: 4px !important;
  padding: 8px 20px !important;
  svg {
    margin-bottom: -2px !important;
    margin-left: 14px;
    font-size: 18px !important;
    opacity: .75;
    font-weight: 600;
  }
}
.usersMenuList {
  top: 12px !important;
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;@header-text-color: #000000;